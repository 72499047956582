import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import CustomSlider from '../../CustomSlider';
import getTransformedImageURLs from '../../../helpers/getTransformedImageURLs';
import { useTopBrandsList } from '../../../hooks/Shop';

const BrandMenu = ({
  className,
  allowGrid,
  topBrands: initialTopBrandsList = [],
  hideTitle,
  title = 'Shop from our top brands',
  showBentoTopBrands = false,
  isSearchPage = false
}) => {
  const router = useRouter();
  const {
    query: { tab },
    route
  } = router;

  const revalidate = route.includes('search');

  const { data: topBrands } = useTopBrandsList({
    initialTopBrandsList: isSearchPage ? null : initialTopBrandsList,
    revalidate
  });
  if (topBrands?.length) {
    return (
      <>
        <div className={`top-brands-slider  mb-0 mb-lg-3 gd-brand-menu  ${allowGrid ? 'brand-sec-home' : ''} ${className}`}>
          <h2 className={hideTitle ? 'd-none' : ''}>{title}</h2>
          <CustomSlider className="">
            {topBrands?.map(({ slug, image }) => {
              if (image) {
                const { base_url: baseUrl, path } = image;
                const logoUrl = baseUrl.concat(path);
                return (
                  <Link prefetch={false} href={{ pathname: `/brands/${slug}`, query: tab && { tab } }}>
                    <a className="cursor-pointer brand-name py-3 flex-none">
                      <picture className="w-100 ">
                        <source srcSet={getTransformedImageURLs(logoUrl, 'webp').srcSmall} type="image/webp" />
                        <source srcSet={getTransformedImageURLs(logoUrl, 'png').srcSmall} type="image/png" />
                        <img className="img-brand" alt="categoryLogo" src={getTransformedImageURLs(logoUrl, 'png').srcSmall} />
                      </picture>
                    </a>
                  </Link>
                );
              }
              return null;
            })}
          </CustomSlider>
        </div>
        {showBentoTopBrands && (
          <h6 class="category-hub-description mb-0 mt-2">
            Explore our <a href="/brands">top cannabis brands</a> at Bento and discover weed you're guaranteed to love. We've
            curated an impressive selection of the finest brands in the SF cannabis community, including
            <a href="/brands/pow"> POW!</a>,<a href="/brands/grassdoor"> Grassdoor</a>,
            <a href="/brands/papa-barkley"> Papa & Barkley</a>,<a href="/brands/hello-again"> Hello Again</a>,
            <a href="/brands/jelly-cannabis"> Jelly Cannabis Co.</a>, STIIIZY, Oakfruitland, Wyld, Generic, Casacanna, Raw Garden,
            Kiva Confections and many more. Trust Bento to bring you the best the cannabis world has to offer.
          </h6>
        )}
      </>
    );
  }
  return null;
};

export default BrandMenu;
